import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import TextTransition, { presets } from "react-text-transition";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}
const TEXTS = [
  "web aplikácie",
  "internetové obchody",
  "interné systémy",
  "sieťové technológie",
  "serverové technológie"
];

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [index, setIndex] = useState(0);

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-14 reveal-from-bottom" data-reveal-delay="200">
              Vytvárame a spravujeme 
            </h1>
            <h2 className="mt-0 mb-14 reveal-from-bottom" data-reveal-delay="200"><TextTransition text={ TEXTS[index % TEXTS.length] } style={{ textAlign: "centre !important" }} className="text-color-primary" inline style={{textAlign: "center"}} springConfig={ presets.slow } direction="up" delay="40" /></h2>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;