import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import { Element } from 'react-scroll';
import Button from '../elements/Button';
import ButtonGroup from '../elements/ButtonGroup';
import axios from 'axios';
import { event } from 'react-ga';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
  topOuterDivider: false,
  topDivider: false
}

export default class Form extends React.Component {    
    constructor(props) {
        super(props)
        this.state = {
          msg: "",
          true_msg: "",
          false_msg: "",
          info_msg: "Zanechajte nám Váš e-mail",
          passingTags: {
            msg: {
              msg: ''
            }
          }
        }
    }
    outerClasses = classNames(
      'cta section center-content-mobile reveal-from-bottom',
      defaultProps.topOuterDivider && 'has-top-divider',
      this.props.bottomOuterDivider && 'has-bottom-divider',
      this.props.hasBgColor && 'has-bg-color',
      this.props.invertColor && 'invert-color',
      this.props.className
    );
    innerClasses = classNames(
      'cta-inner section-inner',
      this.props.topDivider && 'has-top-divider',
      this.props.bottomDivider && 'has-bottom-divider',
      propTypes.split && 'cta-split'
    );  
    send(event){
      event.preventDefault();
      var formData = new FormData();
      const aaaa = this.state.msg;
      formData.append('data',aaaa);
      axios.post('https://apis.student-unity.eu/https://student-unity.eu/send.php', formData)
      .then((response) => {
        this.setState({
          true_msg: response.data
        })
      })
      .catch((error) => {
        console.log(error);
      })
    }
    handleSubmit = e => {
      e.preventDefault();
      const data = {
        msg: this.state.msg
      };
      const config = {'x-requested-with': '*', 'Origin': '*' }
      axios.post("/send.php", data, config)      
      .then((res) => {
        console.log(res); 
        this.setState({true_msg: res.data, info_msg: 'Ďakujeme, ozveme sa Vám do 24 hodín', msg: ''})
        setTimeout(() => {
          this.setState({
            info_msg: "Zanechajte nám Váš e-mail."
          })
        }, 1500)
      })      
      .catch(err => console.log(err));  
    };
    onBodyChange = e => {
      this.setState({
        msg: e.target.value
      });
    };
    render() {
      var {info_msg} = this.state;
      return (
        <section
          {...this.props}
          className={this.outerClasses}
        >
          <div className="container">
            <div
              className={this.innerClasses}
            >
              <div className="cta-slogan">
                <h3 className="m-0">
                <Element name="kontakt" className="element"></Element>
                {info_msg}
                </h3>
              </div>
              <div className="cta-action">
               <form onSubmit={this.handleSubmit}>
                 <ButtonGroup>
                   <div className="has-icon-right">
               <input onChange={this.onBodyChange} value={this.state.msg} data-name="msg" name="msg" className="form-input cta form-input" id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="spolupraca@student-unity.eu">
                </input>
                   </div>
                {/* <Button color="dark" wideMobile>Odoslať</Button> */}
                <button className="button button-wide-mobile button-dark" type="submit" >Odoslať</button>
                 </ButtonGroup>
               </form>
              </div>
            </div>
          </div>
        </section>
      );
    }
}