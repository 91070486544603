import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Form from '../components/sections/Form';
import CookieConsent from 'react-cookie-consent-notification';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      {/* <Testimonial topDivider /> */}
      {/* <Cta split /> */}
      <Form />
      <CookieConsent background={'#5658dd'}  color={'#fff'} buttonFontSize="15" buttonText="Súhlasim" padding="30" bottomPosition="false">Táto stránka používa cookies, aby sme ju mohli postupne vylepšovať a prinášať vám lepšie služby. </CookieConsent>
    </>
  );
}

export default Home;